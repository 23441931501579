import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Colors",
  "author": "Stefan Huber",
  "date": "2018-11-04",
  "layout": "post",
  "draft": true,
  "path": "/articles/colors/",
  "tags": ["TagOne", "TagTwo"],
  "description": "bla bla bla... "
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "this-is-a-stub-dont-read"
    }}>{`this is a stub! don't read!`}</h1>
    <h2 {...{
      "id": "correct-collors"
    }}>{`Correct Collors`}</h2>
    <p>{`Die `}<a parentName="p" {...{
        "href": "https://www.lettertypen.de/"
      }}>{`Lettertypen`}</a>{` kennen einen Lieferanten, der Farben mit Leinöl liefert.`}</p>
    <h2 {...{
      "id": "colors"
    }}>{`Colors`}</h2>
    <p>{`To print in colors take «Kleinoffset ohne Trockenstoff». As for Black you can probably also order «Folienfarbe».`}</p>
    <h3 {...{
      "id": "colos-in-germany"
    }}>{`Colos in Germany`}</h3>
    <p>{`In Germany there is a company called `}<a parentName="p" {...{
        "href": "https://www.gerstaecker.de/"
      }}>{`Gerstaecker`}</a>{` selling speial «Buchdruckfarben». (`}<a parentName="p" {...{
        "href": "https://www.verein-fuer-die-schwarze-kunst.de/community/forum-wissen/druckfarben-hochdruck/#post-13"
      }}>{`Source`}</a>{`)`}</p>
    <h3 {...{
      "id": "colos-in-switzerland"
    }}>{`Colos in Switzerland`}</h3>
    <p>{`hubergroup Schweiz AG`}<br parentName="p"></br>{`
`}{`Buechstrasse 11`}<br parentName="p"></br>{`
`}{`CH-8645 Jona  `}</p>
    <p>{`Dündup Rongtsang → `}<a parentName="p" {...{
        "href": "mailto:Duendup.Rongtsang@hubergroup.com"
      }}>{`Duendup.Rongtsang@hubergroup.com`}</a>{` – +41 55 457 51 38`}</p>
    <h3 {...{
      "id": "links"
    }}>{`Links`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://vimeo.com/78230375"
        }}>{`Four-color Letterpress Printing of the National Geographic Magazine in the Nineteen Fifties and Sixties`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      